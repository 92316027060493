.closet-upload {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}
.closet-upload-box {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #d2d2d2;
    border-radius: 4px;
    position: relative;
}
.closet-upload-box input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.closet-upload-col{
    width: 50%;
}
.closet-upload-col h5{
    margin: 0 0 5px;
   font-size: 14px;
   color: #c85c42;
}
.closet-upload-box figure {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 5px;
}

.closet-upload-box figure img {
    width: 25px;
    margin: 0 auto;
    opacity: 0.5;
}

.closet-upload-box figure p {
    font-size: 10px;
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
    color: #848484;
}
.closet-upload-box figure {
    display: flex;
    flex-direction: column;
    margin: 0;
    gap: 5px;
}

.closet-upload-box figure img {
    width: 25px;
    margin: 0 auto;
    opacity: 0.5;
}

.closet-upload-box figure p {
    font-size: 10px;
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2;
    color: #848484;
}

.closet-upload-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 0;
}

.closet-upload-list-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 8px;
    border-radius: 6px;
}

.closet-upload-list-name img {
    cursor: pointer;
    width: 16px;
}

.closet-upload-list-name span {
    font-size: 12px;
    color: #565656;
    text-overflow: ellipsis;   
    overflow:hidden;              
    white-space:nowrap;
}
.closet-form-field label {
    display: block;
    width: 100%;
    font-size: 14px;
    margin-bottom: 5px;
}

.closet-form-field {
    margin-bottom: 15px;
}
.closet-dialog-inner {
    position: relative;
}
.closet-form-field .MuiSelect-select{
    padding: 8px;
    font-size: 14px;
    max-height: 55px;
    overflow-y: auto !important;
}
.closet-form-field .MuiInput-root {
    width: 100%;
    border: 1px solid #d2d2d2;
    height: 40px;
    border-radius: 6px;
}

.closet-form-field .MuiInput-root:before,
.closet-form-field .MuiInput-root:after {
    display: none;
}

.closet-form-field .MuiInput-root input {
    padding: 10px;
}
.MuiMenu-list {
    max-height: 350px;
}
.closet-row{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.closet-row-col{
    width: calc(50% - 10px);
}
.closet-form-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
.closet-form-button button {
    background-color: #c85c42;
    width: 200px;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.closet-form-button button:hover {
    background-color: #da674f;
}
.closet-wrapper{
    max-width: 1140px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}
.closet-form-button button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.closet-form-color {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.closet-form-color span {
    width: 25px;
    height: 25px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    cursor: pointer;
}
.closet-form-color .closet-form-color-active {
    box-shadow: 0px 0px 5px rgb(0 0 0 / 85%);
}
.closet-form-color span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.closet-form-color {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    align-items: center;
    gap: 10px;
}

.closet-form-color span {
    width: 25px;
    height: 25px;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    cursor: pointer;
}

.closet-form-color span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.closet-form-list {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.closet-form-list li {
    padding: 0;
    text-align: center;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    color: #c85c42;
    width: 80px;
    cursor: pointer;
}
.closet-form-list li.closet-form-list-active img,.closet-form-list li.closet-form-list-active{
    opacity: 1;
    font-weight: bold;

}
.closet-form-list li figure{
    margin: 0;
}
.closet-form-list li figure img {
    width: 30px;
    height: 30px;
    opacity: 0.5;
    color: #c85c42;
}
.closet-form-list li.closet-form-list-active figure img {
    opacity: 1;
}
.closet-dialog-loader .MuiCircularProgress-root{
    color: #c85c42;
}
.closet-dialog-loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 55%);
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width: 767px) {
    .closet-upload {
        flex-direction: column;
    }
    .closet-upload-col {
        width: 100%;
    }
  }