.closet-dialog .MuiPaper-root {
    max-width: 630px;
    width: 100%;
    position: relative;
    padding: 30px;
}
.closet-dialog .MuiPaper-root h2 {
    font-size: 18px;
    margin: 0 0 15px;
    color: #c85c42;
}
.closet-add{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 50px;
    z-index: 9;
    right: 50px;
}

.closet-add-button{
    width: 45px;
    height: 45px;
    background-color: #c85c42;
    border-radius: 100%;
   
    padding: 10px;
   
    border: 0;
    cursor: pointer;
    transition: 0.2s;
   
}
.closet-add-button img {
    width: 26px;
}

.closet-add-button:hover {
    background-color: #da674f;
}
.closet-close {
    background-color: transparent;
    border: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
}
.closet-close:hover {
    opacity: 0.8;
}
.closet-close img {
    width: 20px;
    height: 20px;
}


.closet-card-main {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 30px;
}
.closet-card-col {
    width: calc(33.3% - 13px);
}
.closet-card {
    height: 0;
    position: relative;
    width: 100%;
    padding-top: 70%;
    background-color: #f6f6f6;
    border-radius: 4px;
}
.closet-card video, .closet-card img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 0;
    border-radius: 12px;
    object-fit: cover;
}
.closet-card-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}
.closet-card-loader .MuiCircularProgress-root {
    color: #c85c42;
}
.closet-card-trash {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 2;
    width: 20px;
    height: 20px;
    background-color: #fff;
    padding: 5px;
    border: 1px solid #c85c42;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.closet-card-trash img {
    width: 15px;
    position: static;
    height: 15px;
}
.closet-card-no-data {
    display: flex;
    align-items: center;
    justify-content: center;
}

.closet-card-no-data p {
    color: #c85c42;
}
.closet-card-trash:hover {
    opacity: 0.8;
}
.closet-form-button button .MuiCircularProgress-root {
    color: #fff;
}

.home-tabs-design .MuiButtonBase-root.Mui-selected {
    background-color: #c85c42;
    color: #fff;
}

.home-tabs-design .MuiTouchRipple-root {
    display: none;
}

.home-tabs-design .MuiTabs-indicator {
    display: none;
}

.home-tabs-design .MuiTabs-root {
    padding-top: 15px;
    border-bottom: 1px solid #c85c42;
}

.home-tabs-design .MuiTab-root  {
    padding: 5px 15px;
    min-height: 35px;
    border-radius: 4px 4px 0 0;
}
.MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0) !important;
}

@media only screen and (max-width: 1024px) {

    .closet-card-col {
        width: calc(50% - 10px);
    }
}
@media only screen and (max-width: 767px) {
    .closet-row-col, .closet-card-col {
        width: 100%;
    }

  }