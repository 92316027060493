.book-video-session{
    display:flex;
    justify-content:space-between;
    gap:5px;
    flex-wrap:wrap;
    max-width:800px;
    margin:0 auto;
    align-items:center;
}

.book-video-session h2{
    margin:0;
}