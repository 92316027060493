.closet-header {
    padding: 30px 0;
}

.closet-header-text {
    text-align: center;
}

.closet-header-text h1 {
    margin: 0 0 5px;
    font-size: 26px;
    color: #c85c42;
}

.closet-header-text p {
    font-size: 16px;
    margin: 0 0 15px;
    color: #8f8f8f;
}

.closet-header-filter {
    display: flex;
    gap: 0 30px;
    flex-wrap: wrap;
    margin-top: 30px;
}

.closet-header-filter .closet-form-field {
    width: calc(25% - 23px);
}
.closet-header-filter-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}
.closet-header-filter-btn button span {
    background-color: #fff;
    min-width: 18px;
    border-radius: 100%;
    min-height: 18px;
    color: #c85c42;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}
.closet-header-filter-btn button {
    display: flex;
    align-items: center;
    background-color: #c85c42;
    border: 0;
    border-radius: 30px;
    color: #fff;
    padding: 5px 15px;
    gap: 5px;
    font-size: 16px;
    cursor: pointer;
}

.closet-header-filter-btn button img {
    width: 15px;
}
.closet-form-field .MuiChip-root {
    height: 23px;
    background: #c95c42;
    color: #fff;
}
.MuiSelect-multiple li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root {
    padding: 1px;
    font-size: 14px;
    display: inline;
}
.closet-select-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 35px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}
.closet-select-icon.closet-cross-icon{
  pointer-events: all;
}
.closet-select-icon.closet-cross-icon img{
   width: 15px;
   cursor: pointer;
  }
@media only screen and (max-width: 1024px) {
    .closet-header-filter .closet-form-field {
        width: calc(50% - 15px);
    }
 
}
@media only screen and (max-width: 767px) {

    .closet-header-filter .closet-form-field {
        width: 100%;
    }
 
  }