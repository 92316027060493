.closet-confirm-box .MuiPaper-root {
    width: 100%;
    max-width: 400px;
    padding: 24px;
    text-align: center;
}

.closet-confirm-box-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
.closet-confirm-box .MuiPaper-root h2{
    margin: 0 0 30px 0;
    font-size: 22px;
    color: #c85c42;
}
.closet-confirm-box-inner figure {
    width: 40px;
    border: 1px solid #c95c42;
    height: 40px;
    margin: 0 auto 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.closet-confirm-box-inner figure img {
    width: 22px;
}
.closet-confirm-box-btn button{
    background-color: #c85c42;
    color: #fff;
    border: 0;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
}
.closet-confirm-box-btn button:first-child{
    background-color: #fff;
    color: #c85c42;
    border: 1px solid #c85c42;
}
.closet-confirm-box .MuiPaper-root p {
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #585858;
}