.livechat {
    border: 1px solid #d2d2d2;
    padding: 15px;
    max-width: 500px;
    margin: 0 auto 15px;
}


.livechat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.qr-code {
    text-align: center;
    margin-bottom: 25px;
}
.livechat-header h3 {
    margin: 0;
}

.livechat-header span {
    cursor: pointer;
}

.chatbox {
    height: 200px;
    overflow-y: auto;
    float: left;
    width: 100%;
}
.chat-buttons {
    gap: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.chat-buttons button {
    width: 100%;
}

.chat-buttons textarea {
    height: 59px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    resize: none;
    padding:5px;
}
.loader{
    margin-bottom:10px;
}
.chat-bubble {
    border: 1px solid #d2d2d2;
    display: flex;
    width: 60%;
    margin: 5px 0;
    border-radius: 5px;
    padding: 5px;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f4f4f4;
    float: left;
}

.chat-bubble p {
    margin: 0 5px 0 0;
    font-size: 16px;
}
.chat-bubble.chat-bubble-left {
    float: right;
}
.chat-bubble span {
    font-size: 11px;
    width: 100%;
    text-align: right;
}