.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.form .formInner{
    display: flex;
    gap: 20px;
}
.form .formInner p{
    color: #ff0000;
    font-size: 12px;
}
.form  input,
.form  select{
    border: 1px solid #c6c6c6;
    height: 40px;
    border-radius: 4px;
    padding: 8px 12px;
    color: #5a5858;
    font-size: 14px;
    outline: none;
    width: 100%;
}
.form  select{
    font-weight: 600;
    font-weight: 600;
    min-width: 150px;
    padding-right: 30px;
    /* -moz-appearance:none;
    -webkit-appearance:none; 
    appearance:none;
    background-position: right 6px center;
    background-repeat: no-repeat;
    background-size: 17px; */
}
.form-button-style{
    min-width: 126px;
    align-self: flex-start;
    height: 38px;
    outline: none;
    border: none;
    background: #de7964;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    padding: 0 15px;

}
.form-button-style:hover{
    background: #da674f;
}
.button-wrap-member {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    gap: 40px;
}
.thankyou-msg{
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    flex-wrap: wrap;
    max-width: 880px;
    margin: 30px auto;
}
p{
    line-height: 1.5;
}
.index {
    padding: 15px;
}
.note-design {
    display: inline-block;
    font-size: 14px;
    padding: 5px 0;
    color: orange;
}
.text-align-center{
    text-align: center;
}
.formInputCol {
    width: 50%;
}
.membership-section {
    margin-top: 30px;
}
.content * {
    box-sizing: border-box;
}
.button-wrap{
    display: flex;
    gap: 15px;
    justify-content: center;
}

.formInputCol .MuiInputBase-input {
    padding: 8px 12px 8px;
    font-size: 14px;
}

.formInputCol .MuiInputLabel-root {
    top: -6px;
    font-size: 14px;
}

.formInputCol .MuiInputLabel-root.Mui-focused {
    display: none;
}

.MuiOutlinedInput-notchedOutline legend {display: none;}

.formInputCol .MuiInputLabel-root.MuiInputLabel-shrink {
    display: none;
}

.MuiOutlinedInput-notchedOutline {
    top: 0!important;
    border-color: #c6c6c6 !important;
    outline: 0;
    border-width: 1px !important;
}
.membership-text h2{
font-size: 26px;
margin-bottom: 0;
}
.membership-text h3 {
    font-size: 18px;
    margin: 0 0 5px 0;
}
.mt-0{
    margin-top: 0;
}
.mb-0{
    margin-bottom: 0;
}
.mb-1{
    margin-bottom: 10px;
}
.membership-text h2 span, .membership-text h3 span {
    background-color: #f7e2dd;
    color: #c85c42;
    padding: 2px 5px;
}

.thankyou-msg-img img {
    max-width: 100%;
    max-height: 250px;
}
.button-wrap-member h3 {
    color: #c85c42;
}
.thankyou-msg-img, .thankyou-msg-info {
    width: calc(50% - 10px);
    text-align: left;
}
@media only screen and (max-width: 767px) {
    .thankyou-msg{
        gap: 0;
    }
    .thankyou-msg-img, .thankyou-msg-info {
        width: 100%;
        text-align: center;
    }
}