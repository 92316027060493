.outfitBoards {
    padding: 20px 0;
}
.outfitBoards h3 { font-size: 20px; margin: 0 0 5px; line-height: 1.1; color: #c85c42; }
.outfitBoards p { margin: 0; }
.outfitBoards__card {
    padding: 20px;
    max-width: 700px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #c85c42;
    background-color: #c85d4219;
}
