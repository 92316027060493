
*{
    box-sizing: border-box;
    font-family: arial;
}
.question-wrapper{
    max-width: 960px;
    margin: 15px auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgb(0 0 0 / 9%);
    padding: 15px;
    position: relative;
}
.question-box {
    display: flex;
    flex-wrap: wrap;
    line-height: 1.2;
    min-height: 420px;
width: 100%;
}
.question-box h4 small {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #d80404;
}
.date-fields label{
display: block;
}
.date-fields .MuiFormLabel-root{
    display: none;
}
.date-fields .MuiInputBase-root{
    border: 0;
    padding: 0;
    border-bottom: 1px solid #d2d2d2;
    border-radius: 0;
}
.date-fields .MuiInputBase-root.Mui-focused{
    border-bottom: 1px solid #000;
}
.date-fields .MuiOutlinedInput-notchedOutline{
    border: 0;
}
.date-fields .MuiInputBase-input{
padding: 0;
border-bottom: 0 !important;
}
.error-box.success-message {
    background-color: #c7fbd8;
    color: #167627;
}
.error-box.success-message.profile-message {
    margin: 30px auto;
    display: table;
    font-size: 18px;
    padding: 8px 15px;
}
.question-updown-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: flex;
    gap: 5px;
}

.question-updown-btn button {
    background-color: #000;
    border: 0;
    width: 25px;
    height: 25px;
    cursor: pointer;
}
.question-left {
    width: 50%;
    padding-right: 50px;
}

.question-right {
    width: 50%;
}
.question-box .question-right img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 420px;
    width: 100%;
}
.question-box video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.question-box h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0;
}

.question-box h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
    color: #666;
    margin-top: 0;
}

.question-box p {
    font-size: 16px;
    margin-bottom: 30px;
    color: #666;
    margin-top: 0;
}
.question-button{
    background-color: #000;
    border: 0;
    border-radius: 4px;
    padding: 8px 15px;
    display: inline-flex;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: 0.2s;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.question-button:hover {
    background-color: #595959;
}
.hide-question{
    overflow: hidden;
    display: none;
}



.question-box.single-question-card .question-left {
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.question-box h4 {
    color: #333;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 0;
}
.question-input input{
    border: 0;
    border-bottom: 1px solid #d2d2d2;
    height: 40px;
    width: 100%;
    font-size: 18px;
    outline: 0;
}
.question-input input:focus{
    border-bottom: 1px solid #000;
}
.question-input {
    margin-bottom: 15px;
}
.question-box h4 {
    color: #333;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
}

.question-input {
    margin-bottom: 15px;
}

.question-cards {
    display: flex;
    width: 100%;
}
.question-input-checkbox {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}
.checkbox-image {
    width: 100%;
    height: 160px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-box {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 15px;
    background-color: rgb(247, 230, 230);
    padding: 1px 10px;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: rgb(175, 4, 4);
}
.checkbox-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.checkbox-title {
    display: flex;
    align-items: center;
    gap: 5px;
}
.input-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.input-with-label {
    width: calc(33.3% - 15px);
    position: relative;
}
.input-with-label:not(:last-child):after {
    content: "/";
    position: absolute;
    top: 27px;
}
.checkbox-title p {
    margin: 0;
    font-size: 14px;
    color: #000;
}
.question-upload {
    height: 220px;
    border: 1px dashed;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.question-upload {
    height: 220px;
    border: 1px dashed;
    border-radius: 4px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.question-upload input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.upload-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.upload-info img {
    width: 50px;
}

.upload-info p {
    font-size: 16px;
    margin: 0;
}

.upload-info p small {
    display: block;
    text-align: center;
    margin-top: 5px;
    font-size: 12px;
}
.checkbox-title span {
    width: 18px;
    height: 18px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    min-width: 18px;
}
.checkbox-items input[type="radio"]:checked + .checkbox-group .checkbox-title span {
    background-color: #000;
    color: #fff;
}
.checkbox-items {
    width: calc(25% - 15px);
    border: 1px solid #d2d2d2;
    padding: 15px;
    background-color: #fdfdfd;
    cursor: pointer;
    position: relative;
}
.checkbox-items input[type="radio"]{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
.checkbox-items input[type="radio"]:checked + .checkbox-group .input-checked {
    opacity: 1;
}
.input-checked {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    opacity: 0;
}

.input-checked:after {
    content: "";
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-right: 25px solid #000;
    position: absolute;
    right: -4px;
    transform: rotate(135deg);
    top: -16px;
    z-index: 0;
}

.input-checked img {
    position: relative;
    z-index: 2;
}
.checkbox-items:hover {
    background-color: #ededed;
}
.question-info {
    width: 100%;
}
.question-no {
    font-size: 16px;
    padding-top: 5px;
    padding-right: 10px;
    color: #000;
}
.question-box.single-question-card.card-image-right {
    flex-direction: row-reverse;
}

.question-box.single-question-card.card-image-right .question-left {
    padding-left: 15px;
}
.question-arrow {
    display: flex;
    align-items: center;
    gap: 5px;
}
.question-button img {
    width: 12px;
}
.uploaded-files {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

button.uploaded-delete {
    border: 0;
    padding: 0;
    background-color: #000;
    cursor: pointer;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.uploaded-items {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    background-color: #fff;
    padding: 2px 5px;
    border: 1px dashed #000;
}
button.uploaded-delete img {
    width: 15px;
    height: 15px;
}

button.uploaded-delete:hover {
    background-color: #5c5b5b;
}
.uploaded-items-name {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.or-style {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0;
    max-width: 740px;
}

.or-style span {
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 0 20px;
}

.or-style:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #d8d8d8;
    z-index: -1;
}
@media only screen and (max-width: 767px) {
    .question-left {
        width: 100%;
        padding-right: 0px;
    }
    
    .question-right {
        width: 100%;
        margin-bottom: 15px;
    }
    .question-box {
     flex-direction: column-reverse;
    }
    .upload-question.question-box{
        flex-direction:column;
    }
    .question-box.single-question-card .question-left {
        padding-left: 0;
    }
    
    .question-box.single-question-card {
        flex-direction: column;
    }
    .checkbox-items {
        width: calc(50% - 15px);
    }
}
@media only screen and (max-width: 480px) {
    .checkbox-items {
        width: calc(100% - 15px);
    }
}